import React, { useState, useEffect } from "react"
import MetaTags from "react-meta-tags"
import { Link, useHistory, useLocation } from "react-router-dom"
import {
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  Label,
  Row,
  Modal,
  Card,
  CardImg
} from "reactstrap"
import * as url from "helpers/url_helper"
import SweetAlert from "react-bootstrap-sweetalert"
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props"
import { AvForm, AvField } from "availity-reactstrap-validation"

//i18n
import PropTypes from "prop-types"
import { withTranslation } from "react-i18next"

// import images
import logodark from "../../assets/images/logo-dark.png"
import logolight from "../../assets/images/logo-light.png"
import bgImage from "assets/images/danawa/landing_small.png"
import { Button } from "@material-ui/core"

import { Carousel } from "react-responsive-carousel"
import "react-responsive-carousel/lib/styles/carousel.min.css"
import bannerBlank from "assets/images/danawa/banner.png"

import bgBlue from "assets/images/danawa/bg.svg"

function useQuery() {
  return new URLSearchParams(useLocation().search)
}

const DanawaLanding = props => {
  const history = useHistory()
  const query = useQuery()

  useEffect(() => {
    if (localStorage.getItem("authUser")) {
      history.push("/dashboard")
    }
  }, [])

  return (
    <React.Fragment>
      <div>
        <MetaTags>
          <title>SRON</title>
        </MetaTags>
        <Container fluid className="p-0">
          <Row className="g-0">
            {/* <CarouselPage /> */}
            <Col
              // xl={6}
              className="mx-auto"
              style={{
                maxWidth: "480px",
                backgroundColor: "#27417F",
                backgroundImage: `url(${bgImage})`,
                backgroundSize: "contain",
                // backgroundRepeat: "no-repeat",
              }}
            >
              {/* <img
                src={bgImage}
                alt=""
                style={{
                  position: "absolute",
                  objectFit: "contain",
                  maxWidth: "480px",
                }}
              /> */}
              <div
                className="auth-full-page-content mx-auto"
                style={{ minHeight: "100vh", maxWidth: "480px" }}
              >
                <div className="w-100">
                  <div
                    style={{
                      position: "absolute",
                      bottom: 0,
                      width: "100%",
                      height: "60%",
                      backgroundImage: `url(${bgBlue})`,
                      backgroundSize: "cover"
                    }}
                  />
                  <div className="d-flex flex-column h-100">
                    <Row className="px-3 mb-3 mt-5 mx-0">
                      <Col>
                        {/* <Link to="/dashboard"> */}
                          <span
                            className="mb-0 font-size-24 text-white"
                            style={{ fontWeight: 700 }}
                          >
                            {props.t("SRON")}
                          </span>
                        {/* </Link> */}
                      </Col>
                    </Row>
                    <div className="mt-auto">
                      <Row className="mb-5 px-3">
                        <Carousel
                          infiniteLoop
                          autoPlay
                          swipeable
                          emulateTouch
                          interval={5000}
                          showThumbs={false}
                          className="slider_css"
                          dir="rtl"
                          onClickItem={e => {
                            // bannerData &&
                            //   onClickBanner(
                            //     bannerData[e].linkage_type,
                            //     bannerData[e].linkage_detail
                            //   )
                          }}
                        >
                          {/* {bannerData &&
                        bannerData.map((banner, index) => (
                          <div>
                            <div className="item">
                              <Card className="text-center px-3 pt-3 shadow-none">
                                <CardImg
                                  top
                                  className="img-fluid"
                                  style={{
                                    // height: "280px",
                                    objectFit: "cover",
                                    borderRadius: "14px",
                                    aspectRatio: "3/2",
                                  }}
                                  src={banner.image}
                                  alt=""
                                  onError={e => {
                                    onImageError(e, bannerBlank)
                                  }}
                                />
                              </Card>
                            </div>
                          </div>
                        ))} */}
                          <div>
                            <div className="item">
                              <Card className="text-center px-3 pt-0 shadow-none bg-transparent">
                                <CardImg
                                  top
                                  className="img-fluid"
                                  style={{
                                    // height: "280px",
                                    objectFit: "cover",
                                    borderRadius: "14px",
                                    aspectRatio: "3/1",
                                  }}
                                  src={bannerBlank}
                                  alt=""
                                  onError={e => {
                                    onImageError(e, bannerBlank)
                                  }}
                                />
                              </Card>
                            </div>
                          </div>
                          <div>
                            <div className="item">
                              <Card className="text-center px-3 pt-0 shadow-none bg-transparent">
                                <CardImg
                                  top
                                  className="img-fluid"
                                  style={{
                                    // height: "280px",
                                    objectFit: "cover",
                                    borderRadius: "14px",
                                    aspectRatio: "3/1",
                                  }}
                                  src={bannerBlank}
                                  alt=""
                                  onError={e => {
                                    onImageError(e, bannerBlank)
                                  }}
                                />
                              </Card>
                            </div>
                          </div>
                        </Carousel>
                      </Row>
                      <Row className="mb-3 px-2">
                        <Col>
                          <Link to="/login">
                            <button
                              className={
                                "btn btn-primary btn-block waves-effect waves-light w-100 font-size-18 py-3 text-uppercase"
                              }
                              style={{
                                borderRadius: "8px",
                              }}
                              // onClick={submitLogin}
                            >
                              <strong>{props.t("Sign In")}</strong>
                            </button>
                          </Link>
                        </Col>
                      </Row>
                      <Row className="mb-3 px-2">
                        <Col>
                          <Link to="/sign-up">
                            <button
                              className={
                                "btn btn-primary btn-block waves-effect waves-light w-100 font-size-18 py-3 text-uppercase"
                              }
                              style={{
                                borderRadius: "8px",
                                backgroundColor: "#AF3B30",
                              }}
                              // onClick={submitLogin}
                            >
                              <strong>{props.t("Sign Up To Begin")}</strong>
                            </button>
                          </Link>
                        </Col>
                      </Row>
                    </div>
                    {/* </div> */}
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withTranslation()(DanawaLanding)

DanawaLanding.propTypes = {
t: PropTypes.any,
}
