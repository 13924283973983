import { takeEvery, fork, put, all, call } from "redux-saga/effects"

// Login Redux States
import { EDIT_PROFILE } from "./actionTypes"
import { profileSuccess, profileError } from "./actions"

//Include Both Helper File with needed methods
import { getFirebaseBackend } from "../../../helpers/firebase_helper"
import {
  postFakeProfile,
  postJwtProfile,
} from "../../../helpers/fakebackend_helper"
import * as url from "../../../helpers/url_helper"

const fireBaseBackend = getFirebaseBackend()

function* editProfile({ payload: { user } }) {
  try {
    if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
      const response = yield call(
        fireBaseBackend.editProfileAPI,
        user.username,
        user.idx
      )
      yield put(profileSuccess(response))
    } else if (process.env.REACT_APP_DEFAULTAUTH === "jwt") {
      const response = yield call(postJwtProfile, "/post-jwt-profile", {
        username: user.username,
        idx: user.idx,
      })
      yield put(profileSuccess(response))
    } else if (process.env.REACT_APP_DEFAULTAUTH === "fake") {
      const response = yield call(postFakeProfile, {
        username: user.username,
        idx: user.idx,
      })
      yield put(profileSuccess(response))

      // const submitForm = async () => {
      //   const response = await fetch(url.G5_GET_USERDETAIL + "?uid=" + user.id)
      //   const postresponse = await response.json()

      //   return { status: response.status, postresponse: postresponse }
      // }

      // const response = yield call(submitForm)
      // // console.log(response.status)
      // if (response.status == 200) {
      //   // console.log("in")
      //   localStorage.setItem(
      //     "authUser",
      //     JSON.stringify(response.postresponse.data)
      //   )
      //   yield put(profileSuccess(JSON.stringify(response.postresponse.data)))
      //   // console.log(response.postresponse.data)
      //   // history.push("/dashboard")
      // } else {
      //   // put(apiError(postresponse.error))
      //   // console.log(response.postresponse.error)
      //   yield put(apiError(response.postresponse.error))
      // }
    }
  } catch (error) {
    yield put(profileError(error))
  }
}
export function* watchProfile() {
  yield takeEvery(EDIT_PROFILE, editProfile)
}

function* ProfileSaga() {
  yield all([fork(watchProfile)])
}

export default ProfileSaga
