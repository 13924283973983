import React, { useEffect, useState } from "react"
import ReactDOM from "react-dom"
import App from "App"
import * as Sentry from "@sentry/react"
import { Integrations } from "@sentry/tracing"
import * as serviceWorker from "serviceWorker"
import { BrowserRouter } from "react-router-dom"
import "i18n"
import { Provider } from "react-redux"
//Stripe
import { loadStripe } from "@stripe/stripe-js"
import { Elements } from "@stripe/react-stripe-js"

import store from "store"

// const stripePromise = loadStripe(
//   // "pk_test_51JBD1oG0KJofaMhnV0Uws95WWhF0dN9aB2PATYOsSOWgPsGOYFlG1hWmcVBVIyCzrHSy715Sgfv4OHoVNUxtjfdV00w9RostpI"
//   "pk_test_51IkBKsDXRnGSQdqedwbUkOfmYU1Nc1kmxjkoeczYLCak43RT7H7zMzctgKpmQb5fAETm804cBKh2XdXfYjTWJQyp00US2NNDLy"
// )

// Sentry.init({
//   dsn:
//     "https://89898c7a858a4afb99ecccecdb404280@o644914.ingest.sentry.io/6020314",
//   integrations: [new Integrations.BrowserTracing()],

//   // Set tracesSampleRate to 1.0 to capture 100%
//   // of transactions for performance monitoring.
//   // We recommend adjusting this value in production
//   tracesSampleRate: 1.0,
// })

const app = (
  <Provider store={store}>
    {/* <Elements stripe={stripePromise}> */}
    <BrowserRouter basename="/web">
      <App />
    </BrowserRouter>
    {/* </Elements> */}
  </Provider>
)

ReactDOM.render(app, document.getElementById("root"))
serviceWorker.unregister()
