import React, { useState, useEffect } from "react"
import MetaTags from "react-meta-tags"
import { Link, useHistory, useLocation } from "react-router-dom"
import {
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  Label,
  Row,
  Modal,
} from "reactstrap"
import * as url from "helpers/url_helper"
import SweetAlert from "react-bootstrap-sweetalert"
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props"
import { AvForm, AvField } from "availity-reactstrap-validation"

//i18n
import PropTypes from "prop-types"
import { withTranslation } from "react-i18next"

// import images
import logodark from "../../assets/images/logo-dark.png"
import logolight from "../../assets/images/logo-light.png"
import bgImage from "assets/images/danawa/bg_login.jpg"
import { Button } from "@material-ui/core"

function useQuery() {
  return new URLSearchParams(useLocation().search)
}

const DanawaForgotPassword = props => {
  const history = useHistory()
  const query = useQuery()

  const [pwVisibility, setpwVisibility] = useState(true)

  const togglePwVisibility = () => {
    setpwVisibility(!pwVisibility)
  }

  const [token, setToken] = useState(null)
  const [countryCode, setCountryCode] = useState("65")
  const [phoneNumber, setPhoneNumber] = useState(null)
  const [password, setPassword] = useState(null)
  const [type, setType] = useState(null)
  const [oid, setOid] = useState(null)

  useEffect(() => {
    if (localStorage.getItem("authAPI")) {
      const obj = JSON.parse(localStorage.getItem("authAPI"))
      console.log(
        "storage",
        obj.api_token_expiry + " / " + Math.floor(Date.now() / 1000)
      )
      if (obj.api_token_expiry < Math.floor(Date.now() / 1000)) {
        getToken()
      } else {
        setToken(obj)
      }
    } else {
      getToken()
    }

    if (localStorage.getItem("authUser")) {
      const obj = JSON.parse(localStorage.getItem("authUser"))
      history.push("/home")
      // console.log(obj)
    }

    if (query.get("type")) {
      setType(query.get("type"))
    }

    if (query.get("id")) {
      setOid(query.get("id"))
      localStorage.setItem("redirect_id", query.get("id"))
    }

    if (localStorage.getItem("countryCode")) {
      setCountryCode(localStorage.getItem("countryCode"))
    }
  }, [])

  const getToken = async () => {
    var urlencoded = new URLSearchParams()
    urlencoded.append("option", "get_api_token")
    urlencoded.append("source", "web")
    urlencoded.append("api_key", process.env.REACT_APP_APIKEY)

    const requestOptions = {
      method: "POST",
      body: urlencoded,
    }

    console.log("Request token", urlencoded.toString())

    fetch(url.UGIFT_STAGING_POST_AUTH, requestOptions)
      .then(response => response.text())
      .then(result => console.log(result))
      .catch(error => console.log("error", error))

    const response = await fetch(url.UGIFT_STAGING_POST_AUTH, requestOptions)
    const postresponse = await response.json()

    console.log(response.status, postresponse.message)

    if (response.status == 200 && postresponse.success) {
      setToken(postresponse)
      localStorage.setItem("authAPI", JSON.stringify(postresponse))
      console.log(response.status, token)
    } else {
      // seterror_alert(true)
    }
  }

  const submitLogin = async e => {
    e.preventDefault()
    if (countryCode == null) {
      console.log("country code empty")
      seterror_alert(true)
      setdynamic_title(props.t("Country code empty"))
      setdynamic_description(props.t("Please choose a country code."))
      return
    }

    if (phoneNumber == null) {
      console.log("phone number empty")
      seterror_alert(true)
      setdynamic_title(props.t("Phone number empty"))
      setdynamic_description(props.t("Please enter your phone number."))
      return
    }

    if (password == null) {
      console.log("password empty")
      seterror_alert(true)
      setdynamic_title(props.t("Password empty"))
      setdynamic_description(props.t("Please enter your password."))
      return
    }

    var urlencoded = new URLSearchParams()
    urlencoded.append("option", "login")
    urlencoded.append("phone_number", countryCode + phoneNumber)
    urlencoded.append("password", password)

    const requestOptions = {
      method: "POST",
      headers: {
        Authorization: "Bearer " + token.api_token,
      },
      body: urlencoded,
    }

    console.log("login", urlencoded.toString())

    const response = await fetch(
      url.UGIFT_STAGING_POST_AUTH,
      // "http://47.241.176.8/api/external/auth/",
      requestOptions
    )
    const postresponse = await response.json()
    // response.body
    // setPostResponse(postresponse)
    console.log(response.status, postresponse)

    if (response.status == 200 && postresponse.success) {
      if (postresponse.success) {
        if (!postresponse.migrate_member) {
          localStorage.setItem("authUser", JSON.stringify(postresponse.data))
          if (postresponse.data.country_id) {
            // localStorage.setItem("country", postresponse.data.country_id)
            localStorage.setItem("country", 3)
          }

          if (type == "redirect" && oid != null) {
            history.push("/giftcard-share?id=" + oid)
          } else history.push("/home")
        } else {
          console.log("migration")
          localStorage.setItem("phoneNumber", countryCode + phoneNumber)

          setsuccess_dlg(true)
          setdynamic_title(props.t("Password expired"))
          setdynamic_description(postresponse.message)
        }
      }
    } else {
      seterror_alert(true)
      setdynamic_title(props.t("Login failed"))
      setdynamic_description(postresponse.message)
    }
  }

  const [countryData, setCountryData] = useState(null)
  const getCountryList = async () => {
    var urlencoded = new URLSearchParams()
    urlencoded.append("option", "list_country")
    urlencoded.append("order_by", "ASC")

    const requestOptions = {
      method: "POST",
      // mode: "no-cors",
      headers: {
        // "Content-Type": "application/json",
        Authorization: "Bearer " + token.api_token,
      },
      body: urlencoded,
    }

    console.log("Request country", urlencoded.toString())

    const response = await fetch(
      url.UGIFT_STAGING_PROFILE,
      // "http://47.241.176.8/api/external/auth/",
      requestOptions
    )
    const postresponse = await response.json()
    // response.body
    // setPostResponse(postresponse)
    console.log(response.status, postresponse)

    if (response.status == 200) {
      // history.push("/tables-applicationtable")
      setCountryData(postresponse.table)
      if (postresponse.table.length > 0 && countryCode == null) {
        setCountryCode(postresponse.table[0].country_code.replace("+", ""))
      }
    } else {
      // seterror_alert(true)
    }
  }

  useEffect(() => {
    if (token) {
      getCountryList()
    }
  }, [token])

  function onCountryCodeChange(e) {
    setCountryCode(e.target.value)
  }

  const [loginEnabled, setLoginEnabled] = useState(false)

  function onPhoneNumberChange(e) {
    setPhoneNumber(e.target.value)
    if (e.target.value != "")
      setLoginEnabled(true)
    else setLoginEnabled(false)
  }

  function onPasswordChange(e) {
    setPassword(e.target.value)
    if (e.target.value.length >= 6 && phoneNumber != "" && phoneNumber != null)
      setLoginEnabled(true)
    else setLoginEnabled(false)
  }

  const [success_dlg, setsuccess_dlg] = useState(false)
  const [success_dlg_noaction, setsuccess_dlg_noaction] = useState(false)
  const [error_alert, seterror_alert] = useState(false)
  const [dynamic_title, setdynamic_title] = useState("")
  const [dynamic_description, setdynamic_description] = useState("")

  function onClickFBLogin() {
    FB.login(
      function (response) {
        if (response.authResponse) {
          console.log("Welcome!  Fetching your information.... ")
          FB.api(
            "/me?fields=id,name,email,picture.width(300).height(300),friends",
            function (response) {
              console.log("Good to see you", response)
              setFbResponse(response)
            }
          )
        } else {
          console.log("User cancelled login or did not fully authorize.")
        }
      },
      { scope: "public_profile,user_friends" }
    )
  }

  const [fbResponse, setFbResponse] = useState(null)
  const responseFacebook = response => {
    console.log("facebook", response)
    if (response.accessToken) {
      setFbResponse(response)
      localStorage.setItem("fbToken", response.accessToken)
    }
    // setData(response)
    // setPicture(response.picture.data.url)
    // if (response.accessToken) {
    //   setLogin(true)
    // } else {
    //   setLogin(false)
    // }
  }

  useEffect(() => {
    if (fbResponse) loginWithFacebook()
  }, [fbResponse])

  const [uid, setUid] = useState(null)
  const loginWithFacebook = async () => {
    var urlencoded = new URLSearchParams()
    urlencoded.append("option", "login_facebook")
    urlencoded.append("facebook_id", fbResponse.id)
    urlencoded.append("full_name", fbResponse.name)
    urlencoded.append("profile_picture_link", fbResponse.picture.data.url)

    const requestOptions = {
      method: "POST",
      headers: {
        Authorization: "Bearer " + token.api_token,
      },
      body: urlencoded,
    }

    console.log("login with fb", urlencoded.toString())

    const response = await fetch(
      url.UGIFT_STAGING_POST_AUTH,
      // "http://47.241.176.8/api/external/auth/",
      requestOptions
    )
    const postresponse = await response.json()
    // response.body
    // setPostResponse(postresponse)
    console.log(response.status, postresponse)

    if (response.status == 200 && postresponse.success) {
      if (postresponse.success) {
        console.log("success", postresponse)
        setUid(postresponse.data.member_id)
        localStorage.setItem("authUser", JSON.stringify(postresponse.data))
        if (postresponse.data.country_id != "0") {
          // localStorage.setItem("country", postresponse.data.country_id)
          localStorage.setItem("country", 3)
          // console.log("go to home")
          history.push("/home")
        } else {
          tog_yt()
        }
      }
    } else {
      seterror_alert(true)
      setdynamic_title("Login Facebook failed")
      setdynamic_description(postresponse.message)
    }
  }

  const [modal_yt, setmodal_yt] = useState(false)

  function tog_yt() {
    setmodal_yt(!modal_yt)
    removeBodyCss()
  }

  function removeBodyCss() {
    document.body.classList.add("no_padding")
  }

  const [referralCode, setReferralCode] = useState(null)
  function onReferralCodeChange(e) {
    setReferralCode(e.target.value)
  }

  const [countryId, setCountryId] = useState(null)
  function onCountryIdChange(e) {
    setCountryId(e.target.value)
  }

  const updateCountry = async () => {
    if (countryId == null) {
      console.log("country code empty")
      seterror_alert(true)
      setdynamic_title(props.t("Country empty"))
      setdynamic_description(props.t("Please choose a country."))
      return
    }

    var urlencoded = new URLSearchParams()
    urlencoded.append("option", "update_country")
    urlencoded.append("country_id", countryId)
    urlencoded.append("member_id", uid)

    const requestOptions = {
      method: "POST",
      headers: {
        Authorization: "Bearer " + token.api_token,
      },
      body: urlencoded,
    }

    console.log("login", urlencoded.toString())

    const response = await fetch(
      url.UGIFT_STAGING_POST_AUTH,
      // "http://47.241.176.8/api/external/auth/",
      requestOptions
    )
    const postresponse = await response.json()
    // response.body
    // setPostResponse(postresponse)
    console.log(response.status, postresponse)

    if (response.status == 200 && postresponse.success) {
      if (postresponse.success) {
        // localStorage.setItem("country", countryId)
        localStorage.setItem("country", 3)
        if (referralCode) submitReferralCode(uid)
        else {
          history.push("/home")
          // console.log("go to home")
        }
      }
    } else {
      seterror_alert(true)
      setdynamic_title(props.t("Login failed"))
      setdynamic_description(postresponse.message)
    }
  }

  const submitReferralCode = async uid => {
    var urlencoded = new URLSearchParams()
    urlencoded.append("option", "apply_referral_code")
    urlencoded.append("member_id", uid)
    urlencoded.append("referral_code", referralCode)

    const requestOptions = {
      method: "POST",
      headers: {
        Authorization: "Bearer " + token.api_token,
      },
      body: urlencoded,
    }

    console.log("Apply referral", urlencoded.toString())

    const response = await fetch(
      url.UGIFT_STAGING_PROFILE,
      // "http://47.241.176.8/api/external/auth/",
      requestOptions
    )
    const postresponse = await response.json()
    // response.body
    // setPostResponse(postresponse)
    console.log(response.status, postresponse)

    if (response.status == 200) {
      // console.log("go to home")
      history.push("/home")
    } else {
      seterror_alert(true)
      setdynamic_title(props.t("Referral update failed"))
      setdynamic_description(postresponse.message)
    }
  }

  return (
    <React.Fragment>
      <div>
        <MetaTags>
          <title>SRON | Forgot Password</title>
        </MetaTags>
        <Container fluid className="p-0">
          <Row className="g-0">
            {/* <CarouselPage /> */}
            {success_dlg_noaction ? (
              <SweetAlert
                success
                title={dynamic_title}
                onConfirm={() => {
                  setsuccess_dlg_noaction(false)
                }}
              >
                {dynamic_description}
              </SweetAlert>
            ) : null}
            {success_dlg ? (
              <SweetAlert
                info
                title={dynamic_title}
                onConfirm={() => {
                  history.push("/reset-password")
                }}
              >
                {dynamic_description}
              </SweetAlert>
            ) : null}
            {error_alert ? (
              <SweetAlert
                title={dynamic_title}
                danger
                onConfirm={() => {
                  seterror_alert(false)
                }}
              >
                {dynamic_description}
              </SweetAlert>
            ) : null}
            <Col
              // xl={6}
              className="mx-auto"
              style={{
                maxWidth: "480px",
                backgroundColor: "#27417F",
                backgroundImage: `url(${bgImage})`,
                backgroundSize: "cover",
              }}
            >
              {/* <img
                src={bgImage}
                alt=""
                style={{
                  position: "absolute",
                  objectFit: "contain",
                  maxWidth: "480px",
                }}
              /> */}
              <div
                className="auth-full-page-content mx-auto"
                style={{ minHeight: "100vh", maxWidth: "480px" }}
              >
                <div className="w-100">
                  <div className="d-flex flex-column h-100">
                    {/* <div className=""> */}
                    <Row className="px-3 mb-3 mt-5 mx-0">
                      <Col xs={"auto"} className="pe-0">
                        <Link to="/login">
                          <button className="btn btn-outline-light btn-rounded btn-block waves-effect waves-light font-size-24 text-white">
                            <i className="bx bx-arrow-back align-middle font-size-24" />
                          </button>
                        </Link>
                      </Col>
                    </Row>
                    <div className="mx-3" style={{ marginTop: "150px" }}>
                      <Form>
                        <Row className="mb-3 px-2">
                          <Col>
                            <p className="font-size-24 text-white font-weight-semibold">
                              {props.t("Reset Password")}
                            </p>
                            <span className="font-size-14 text-white">
                              {props.t(
                                "Enter your email address to reset your password"
                              )}
                            </span>
                          </Col>
                        </Row>
                        <Row className="mb-3 px-2">
                          <Col>
                            <Input
                              type="text"
                              className="form-control font-size-16 py-3"
                              id="userMobile"
                              placeholder={props.t("Email Address")}
                              style={{ borderRadius: "8px", border: "none" }}
                              onChange={onPhoneNumberChange}
                            />
                          </Col>
                        </Row>
                      </Form>

                      {/* <Row className="mb-3 px-2">
                        <Col>
                          <Link to="/register">
                            <button
                              className="btn btn-block btn-primary waves-effect waves-light w-100 font-size-18"
                              type="submit"
                              style={{
                                // backgroundColor: "white",
                                // color: "#5165D3",
                                borderRadius: "8px",
                              }}
                            >
                              <strong>{props.t("Create Account")}</strong>
                            </button>
                          </Link>
                        </Col>
                      </Row> */}
                      {/* <Row className="mb-3 px-2">
                          <Col>
                            <button
                              className="btn btn-block waves-effect waves-light w-100 font-size-18"
                              style={{
                                backgroundColor: "#1877F2",
                                color: "white",
                                borderRadius: "8px",
                              }}
                              onClick={onClickFBLogin}
                            >
                              <i className="bx bxl-facebook-circle font-size-20 align-middle me-2 text-white"></i>{" "}
                              <strong>Login with Facebook</strong>
                            </button>
                          </Col>
                        </Row> */}
                      {/* <Row className="mb-3 px-2">
                          <Col>
                            {!login && (
                              <FacebookLogin
                                appId="557886072123636"
                                autoLoad={false}
                                fields="id,name,email,picture.width(300).height(300)"
                                scope="public_profile,user_friends"
                                callback={responseFacebook}
                                icon="fa-facebook"
                                render={renderProps => (
                                  <button
                                    className="btn btn-block waves-effect waves-light w-100 font-size-18"
                                    style={{
                                      backgroundColor: "#1877F2",
                                      color: "white",
                                      borderRadius: "8px",
                                    }}
                                    onClick={e => {
                                      e.preventDefault()
                                      renderProps.onClick()
                                    }}
                                  >
                                    <i className="bx bxl-facebook-circle font-size-20 align-middle me-2 text-white"></i>{" "}
                                    <strong>Login with Facebook</strong>
                                  </button>
                                )}
                              />
                            )}
                          </Col>
                        </Row> */}
                      {/* <Row className="mb-3 px-2">
                            <Col>
                              <Link to="#">
                                <button
                                  className="btn btn-block waves-effect waves-light w-100 font-size-18"
                                  type="submit"
                                  style={{
                                    backgroundColor: "#1877F2",
                                    color: "white",
                                    borderRadius: "8px",
                                  }}
                                >
                                  <i className="bx bxl-facebook-circle font-size-20 align-middle me-2 text-white"></i>{" "}
                                  <strong>Login with Facebook</strong>
                                </button>
                              </Link>
                            </Col>
                          </Row> */}
                    </div>
                    <div
                      className="mt-auto"
                      style={{ backgroundColor: "#27417F" }}
                    >
                      <Row className="my-3 px-2">
                        <Col>
                          {/* <Link to="#"> */}
                          <button
                            className={
                              loginEnabled
                                ? "btn btn-primary btn-block waves-effect waves-light w-100 font-size-18 py-3 text-uppercase"
                                : "btn btn-light btn-block waves-effect waves-light w-100 font-size-18 text-muted py-3 text-uppercase"
                            }
                            // type="submit"
                            disabled={!loginEnabled}
                            style={{ borderRadius: "8px" }}
                            onClick={submitLogin}
                          >
                            <strong>{props.t("Reset")}</strong>
                          </button>
                          {/* </Link> */}
                        </Col>
                      </Row>
                    </div>
                    {/* </div> */}
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <Modal
        isOpen={modal_yt}
        toggle={() => {
          tog_yt()
        }}
        // className="modal modal-static"
        // centered={true}
        backdrop={"static"}
        id="staticBackdrop"
      >
        {/* <div className="modal-header" style={{ borderBottom: "none" }}>
          <h5 className="modal-title mt-0" id="myModalLabel">
            {"Choose Location"}
          </h5>
          <button
            type="button"
            onClick={() => {
              tog_yt(false)
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div> */}
        <div className="modal-body">
          <AvForm>
            <Row>
              <Label
                className="font-size-15 font-weight-bold text-center"
                style={{ color: "#616E7C" }}
              >
                {props.t("Choose your country")}
              </Label>
              <Col>
                <AvField
                  className="mb-3 form-select font-size-15"
                  type="select"
                  // label={props.t("Participant")}
                  // helpMessage={props.t("Gender Placeholder")}
                  // value={form.country_id}
                  name="country_id"
                  onChange={onCountryIdChange}
                  errorMessage={"Field required"}
                  validate={{ required: { value: true } }}
                  style={{
                    borderRadius: "8px",
                    height: "50px",
                  }}
                >
                  {countryData &&
                    countryData.map((data, index) => (
                      <option value={data.id}>{data.name}</option>
                    ))}
                </AvField>
              </Col>
            </Row>
          </AvForm>
          <Row className="justify-content-center">
            <Label
              className="font-size-15 font-weight-bold text-center"
              style={{ color: "#616E7C" }}
            >
              {props.t("Enter your referral code")}
            </Label>
            <Col>
              <InputGroup>
                <Input
                  type="text"
                  className="form-control font-size-15 text-center"
                  id="userReferral"
                  placeholder={props.t("Enter your referral Code")}
                  style={{
                    height: "50px",
                    // border: "none",
                    borderTopLeftRadius: "8px",
                    borderBottomLeftRadius: "8px",
                  }}
                  onChange={onReferralCodeChange}
                />
              </InputGroup>
            </Col>
          </Row>
        </div>
        <div className="modal-footer">
          <button
            type="button"
            onClick={() => {
              updateCountry()
              // tog_yt()
            }}
            className="btn btn-primary waves-effect"
            data-dismiss="modal"
          >
            {props.t("Apply")}
          </button>
          {/* <button
            type="button"
            className="btn btn-primary waves-effect waves-light"
          >
            Save changes
          </button> */}
        </div>
      </Modal>
    </React.Fragment>
  )
}

export default withTranslation()(DanawaForgotPassword)

DanawaForgotPassword.propTypes = {
  t: PropTypes.any,
}
